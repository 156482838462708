<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { majorStage } from '@/utils/public';

export default {
  name: "college",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        dataUrl: '/school/list',
        filter: {
          initUrl: '/school/getCountryList',
          controls: [
            {
              key: 'schoolName',
              label: '学校名称',
              type: 'text'
            },
            {
              key: 'countryId',
              label: '所在国家',
              type: 'select',
              config: {
                options: 'data',
                textKey: 'countryRegion',
                valueKey: 'id'
              }
            },
            {}
          ]
        },
        columns: [
          {
            key: 'schoolName',
            width: '120px',
            title: '学校名称'
          },
          {
            key: 'countryName',
            width: '120px',
            title: '国家'
          },
          {
            key: 'professionalNum',
            width: '120px',
            title: '专业',
            type: 'link',
            config: {
              path: "/college/collegeMajor",
              text: (val) => {
                return '专业 ' + val
              },
              query: 'schoolName'
            }
          },
          {
            key: 'undergraduateToefl',
            width: '120px',
            title: '本科申请语言成绩',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                return `托福${val.undergraduateToefl ? val.undergraduateToefl : '暂无'}/雅思${val.undergraduateIelts ? val.undergraduateIelts : '暂无'}`
              }
            }
          },
          {
            key: 'undergraduateGpa',
            width: '120px',
            title: '本科申请GPA'
          },
          {
            key: 'undergraduateTuition',
            width: '120px',
            title: '本科年预算',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                if (val.undergraduateTuition && val.undergraduateFoodAndAccommodation) {
                  return `￥${parseFloat(val.undergraduateTuition) + parseFloat(val.undergraduateFoodAndAccommodation)}`
                } else if (val.undergraduateTuition && !val.undergraduateFoodAndAccommodation) {
                  return `￥${parseFloat(val.undergraduateTuition)}`
                } else if (!val.undergraduateTuition && val.undergraduateFoodAndAccommodation) {
                  return `￥${parseFloat(val.undergraduateFoodAndAccommodation)}`
                } else {
                  return '-'
                }
              }
            }
          },
          {
            key: 'masterToefl',
            width: '120px',
            title: '研究生申请语言成绩',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                return `托福${val.masterToefl ? val.masterToefl : '暂无'}/雅思${val.masterIelts ? val.masterIelts : '暂无'}`
              }
            }
          },
          {
            key: 'masterGPA',
            width: '120px',
            title: '研究生申请GPA'
          },
          {
            key: 'masterTuition',
            width: '120px',
            title: '研究生年预算',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                if (val.masterTuition && val.masterFoodAndAccommodation) {
                  return `￥${parseFloat(val.masterTuition) + parseFloat(val.masterFoodAndAccommodation)}`
                } else if (val.masterTuition && !val.masterFoodAndAccommodation) {
                  return `￥${parseFloat(val.masterTuition)}`
                } else if (!val.masterTuition && val.masterFoodAndAccommodation) {
                  return `￥${parseFloat(val.masterFoodAndAccommodation)}`
                } else {
                  return '-'
                }
              }
            }
          },
          {
            key: 'name',
            title: '操作',
            fixed: 'right',
            type: 'actions',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '230px'
          }
        ],
        actions: [
          {
            key: 'add',
            text: '新建院校',
            icon: 'plus',
            type: 'form',
            permission: '/school/add',
            config: {
              color: 'primary',
              title: '新建院校',
              submitUrl: '/school/add',
              initUrl: "/school/getCountryList",
              submitText: '确认添加',
              controls: [
                {
                  key: 'schoolName',
                  label: '学校名',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 20, message: '只能输入1-20个字符' }
                    ]
                  }
                },
                {
                  key: 'englishName',
                  label: '学校英文名',
                  type: 'spaceText',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 100, message: '只能输入1-100个字符' }
                    ]
                  }
                },
                {
                  key: 'countryId',
                  label: '所在国家',
                  type: 'select',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'countryRegion',
                    valueKey: 'id'
                  }
                },
                {
                  key: 'address',
                  label: '所在州市',
                  type: 'text',
                  required: true,
                  tips: '输入州-市。例.伊利诺伊州-芝加哥',
                  config: {
                    rules: [
                      { min: 1, max: 20, message: '只能输入1-20个字符' }
                    ]
                  }
                },
                {
                  key: 'logo',
                  label: 'logo',
                  tips: "建议上传方形或圆形 ；大小不超过500kb的图片",
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 500
                  }
                },
                {
                  key: 'picture',
                  label: '图片',
                  tips: "建议上传比例为 604:432 ；大小不超过2m的图片；不超过9张",
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 1024 * 2000
                  }
                },
                {
                  key: 'schoolIntroduction',
                  label: '学校简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  text: '院校概况',
                  type: 'title',
                },
                {},
                {
                  key: 'establishmentTime',
                  label: '成立时间',
                  type: 'text',
                  placeholder: '例.1890年'
                },
                {
                  key: 'numberOfSchools',
                  label: '学校人数',
                  type: 'text',
                  placeholder: '例.12962人'
                },
                {
                  key: 'facultyStudentRatio',
                  label: '师生比例',
                  type: 'text',
                  placeholder: '例.1:5'
                },
                {
                  key: 'genderRatio',
                  label: '男女比例',
                  type: 'text',
                  placeholder: '例.53:47'
                },
                {
                  key: 'graduationSalary',
                  label: '毕业薪资',
                  type: 'numeric',
                  placeholder: '例.50000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'tuitionReference',
                  label: '学费参考',
                  type: 'numeric',
                  placeholder: '例.50000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'characteristicsOfTheInstitution',
                  label: '院校特点',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  key: 'collegeAccommodation',
                  label: '院校食宿',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  key: 'geographicalPosition',
                  label: '地理位置',
                  type: 'text',
                  placeholder: '例.-87.59871 , 41.78861',
                  tips: '可通过“经纬度查询定位”网站查询'
                },
                {}
              ]
            }
          }
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            icon: 'edit',
            permission: '/school/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: '/school/update/get',
              submitUrl: '/school/update',
              submitText: '确认',
              otherUrls: [
                {
                  url: '/school/getCountryList',
                  nameKey: 'data',
                  type: 'select'
                }
              ],
              controls: [
                {
                  key: 'schoolName',
                  label: '学校名',
                  type: 'text',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 20, message: '只能输入1-20个字符' }
                    ]
                  }
                },
                {
                  key: 'englishName',
                  label: '学校英文名',
                  type: 'spaceText',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 100, message: '只能输入1-100个字符' }
                    ]
                  }
                },
                {
                  key: 'countryId',
                  label: '所在国家',
                  type: 'select',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'countryRegion',
                    valueKey: 'id'
                  }
                },
                {
                  key: 'address',
                  label: '所在州市',
                  type: 'text',
                  required: true,
                  tips: '输入州-市。例.伊利诺伊州-芝加哥',
                  config: {
                    rules: [
                      { min: 1, max: 20, message: '只能输入1-20个字符' }
                    ]
                  }
                },
                {
                  key: 'logo',
                  label: 'logo',
                  tips: "建议上传方形或圆形 ；大小不超过500kb的图片",
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 500
                  }
                },
                {
                  key: 'picture',
                  label: '图片',
                  tips: "建议上传比例为 604:432 ；大小不超过2m的图片；不超过9张",
                  type: 'file',
                  required: true,
                  config: {
                    uploadUrl: '/api/school/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    length: 9,
                    maxSize: 1024 * 2000
                  }
                },
                {
                  key: 'schoolIntroduction',
                  label: '学校简介',
                  type: 'textarea',
                  required: true,
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  text: '院校概况',
                  type: 'title',
                },
                {},
                {
                  key: 'establishmentTime',
                  label: '成立时间',
                  type: 'text',
                  placeholder: '例.1890年'
                },
                {
                  key: 'numberOfSchools',
                  label: '学校人数',
                  type: 'text',
                  placeholder: '例.12962人'
                },
                {
                  key: 'facultyStudentRatio',
                  label: '师生比例',
                  type: 'text',
                  placeholder: '例.1:5'
                },
                {
                  key: 'genderRatio',
                  label: '男女比例',
                  type: 'text',
                  placeholder: '例.53:47'
                },
                {
                  key: 'graduationSalary',
                  label: '毕业薪资',
                  type: 'numeric',
                  placeholder: '例.50000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'tuitionReference',
                  label: '学费参考',
                  type: 'numeric',
                  placeholder: '例.50000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'characteristicsOfTheInstitution',
                  label: '院校特点',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  key: 'collegeAccommodation',
                  label: '院校食宿',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  key: 'geographicalPosition',
                  label: '地理位置',
                  type: 'text',
                  placeholder: '例.-87.59871 , 41.78861',
                  tips: '可通过“经纬度查询定位”网站查询'
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '申请参考',
            type: 'form',
            icon: 'edit',
            permission: '/school/apply',
            config: {
              color: 'primary',
              title: '申请参考',
              initUrl: '/school/apply/get',
              submitUrl: '/school/apply',
              submitText: '确认',
              controls: [
                {
                  text: '研究生申请参考：',
                  type: 'title',
                  key: 'masterId'
                },
                {},
                {
                  key: 'masterAverageAdmissionRate',
                  label: '平均录取率',
                  type: 'text',
                  placeholder: '例.1:5'
                },
                {
                  key: 'masterToefl',
                  label: '托福成绩',
                  type: 'text',
                  placeholder: '例.100'
                },
                {
                  key: 'masterIelts',
                  label: '雅思成绩',
                  type: 'text',
                  placeholder: '例.6.5',
                  rules: [
                    { min: 0, max: 15, message: '最多输入15个汉字' }
                  ]
                },
                {
                  key: 'masterGPA',
                  label: 'GPA均分(4分制)',
                  type: 'text',
                  placeholder: '例.GPA3.9'
                },
                {
                  key: 'masterSchoolStartDate',
                  label: '开学日期',
                  type: 'text',
                  placeholder: '例.8-9月'
                },
                {
                  key: 'masterTuition',
                  label: '学费(年)',
                  type: 'numeric',
                  placeholder: '例.500000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'masterFoodAndAccommodation',
                  label: '食宿(年)',
                  type: 'numeric',
                  placeholder: '例.500000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'masterApplicationMaterials',
                  label: '申请材料',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 0, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  text: '本科申请参考：',
                  type: 'title',
                  key: 'undergraduateId'
                },
                {},
                {
                  key: 'undergraduateAdmissionRate',
                  label: '平均录取率',
                  type: 'text',
                  placeholder: '例.1:5'
                },
                {
                  key: 'undergraduateToefl',
                  label: '托福成绩',
                  type: 'text',
                  placeholder: '例.100'
                },
                {
                  key: 'undergraduateIelts',
                  label: '雅思成绩',
                  type: 'text',
                  placeholder: '例.6.5',
                  rules: [
                    { min: 0, max: 15, message: '最多输入15个汉字' }
                  ]
                },
                {
                  key: 'undergraduateGpa',
                  label: 'GPA均分(4分制)',
                  type: 'text',
                  placeholder: '例.GPA3.9'
                },
                {
                  key: 'undergraduateSchoolStartDate',
                  label: '开学日期',
                  type: 'text',
                  placeholder: '例.8-9月'
                },
                {
                  key: 'undergraduateTuition',
                  label: '学费(年)',
                  type: 'numeric',
                  placeholder: '例.500000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'undergraduateFoodAndAccommodation',
                  label: '食宿(年)',
                  type: 'numeric',
                  placeholder: '例.500000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'undergraduateApplicationMaterials',
                  label: '申请材料',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 0, max: 800, message: '只能输入1-800个字符' }
                    ]
                  }
                },
                {
                  text: '',
                  type: 'title',
                  key: 'collegeLibraryManagementId'
                },
                {}
              ]
            }
          }
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>